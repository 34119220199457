import { getCurrentSessionUtm, setGoogleAdwordsInfo } from '../utils/helper';
import { eventFormSubmission } from './analytics/ga4CustomEvents';
import { getQueryFromStorage } from '../hooks/useQueryCatcher';
import TagManager from 'react-gtm-module';
import { useCookies } from 'react-cookie';
import Cookies from 'js-cookie';

const CRM_URL = '/api/contact';
const EMAIL_URL = '/api/contact/email';
const FB_URL = '/api/crm/fb-conversion';
const PINTEREST_URL = '/api/crm/pint-conversion';
const COOKIE_NAME = 'form_submission';
const COOKIE_EXPIRY = 60 * 60 * 24; // 24 hours in seconds

// Helper function to check if a cookie exists
const checkIfSubmitted = (formId) => {
  if (typeof document === 'undefined') return false;
  
  try {
    console.log('Checking for cookie', COOKIE_NAME);
    // Only use js-cookie for reliability
    const cookieValue = Cookies.get(COOKIE_NAME);
    console.log('Cookie check result:', { cookieFound: !!cookieValue, value: cookieValue || 'not found' });
    return !!cookieValue;
  } catch (error) {
    console.error('Error checking for submission cookie:', error);
    return false; // Default to false if there's an error
  }
};

// Helper function to set a submission cookie
const setSubmissionCookie = (formId) => {
  if (typeof document === 'undefined') return;
  
  try {
    const timestamp = Date.now();
    console.log('Setting cookie:', COOKIE_NAME);
    
    // Use only js-cookie for consistency
    Cookies.set(COOKIE_NAME, timestamp.toString(), { 
      expires: 1, // 1 day
      path: '/',
      sameSite: 'Lax'
    });
    
    console.log('Cookie set complete:', { cookieName: COOKIE_NAME, value: timestamp });
    return true;
  } catch (error) {
    console.error('Error setting submission cookie:', error);
    return false;
  }
};

export const submitForm = async (values, options = {}) => {
  console.log('===== Form submission started =====');
  
  try {
    const { formId, locationContext, isIOS, bypassCookieCheck = false } = options;
    console.log('Form options:', { formId, bypassCookieCheck });
    
    // Basic validation
    if (!values || !values.email) {
      console.error('Missing required form fields');
      return { success: false, error: 'Missing required form fields' };
    }
    
    // Check for existing submission cookie and set new one if needed
    let isDuplicate = false;
    if (!bypassCookieCheck) {
      try {
        isDuplicate = checkIfSubmitted(formId);
        console.log('Duplicate check result:', isDuplicate);
        
        // Always set cookie regardless (belt and suspenders)
        setSubmissionCookie(formId);
      } catch (cookieError) {
        console.error('Cookie error, continuing anyway:', cookieError);
      }
    }
    
    // Create a simple basic data object
    const baseData = {
      email: values.email,
      phone: values.phone || '',
      first_name: values.first_name || values.full_name?.split(' ')[0] || '',
      last_name: values.last_name || (values.full_name?.split(' ').slice(1).join(' ') || ''),
      client: 'classyclosets',
      form_id: formId || 'consultation-form',
      message: values.message || '',
      tenantId: values.tenantId || locationContext?.tenantId || '',
      city: locationContext?.city || '',
      state: locationContext?.state || '',
      showroom: values.showroom || ''
    };
    
    // For duplicates, skip all API calls but return successful response
    if (isDuplicate) {
      console.log('=== Duplicate submission detected - showing success but skipping API calls ===');
      return {
        success: true,
        alreadySubmitted: true,
        timestamp: new Date().toISOString(),
        formData: values
      };
    }
    
    console.log('Sending base data to API endpoints');
    
    // Simplified API calls approach - sequential for reliability
    let crmResult = null;
    let emailResult = null;
    let fbResult = null;
    let pinterestResult = null;
    
    // Step 1: Always do CRM submission
    try {
      console.log('Calling CRM API');
      const crmResponse = await fetch(CRM_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(baseData)
      });
      crmResult = await crmResponse.json();
      console.log('CRM API response received');
    } catch (crmError) {
      console.error('CRM submission error:', crmError);
      crmResult = { error: crmError.message };
    }
    
    // Step 2: Always do email notification
    try {
      console.log('Calling Email API');
      const emailResponse = await fetch(EMAIL_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(baseData)
      });
      emailResult = await emailResponse.json();
      console.log('Email API response received');
    } catch (emailError) {
      console.error('Email submission error:', emailError);
      emailResult = { error: emailError.message };
    }
    
    // Step 3: Do analytics for new submissions
    try {
      console.log('Sending analytics events');
      // GTM
      TagManager.dataLayer({
        dataLayer: {
          crmOrClassic: "classic",
          formId: formId
        },
      });
      
      // GA4
      const eventParams = {
        email: values.email,
        phone: values.phone,
        crmOrClassic: "classic",
        page_location: window.location.href,
        page_title: document.title,
        formId: formId
      };
      await eventFormSubmission(window.location.href, "lead", eventParams);
      console.log('Analytics events sent');
    } catch (analyticsError) {
      console.error('Analytics error (continuing anyway):', analyticsError);
    }
    
    // Step 4: Facebook conversion for new submissions
    try {
      console.log('Calling Facebook conversion API');
      const fbResponse = await fetch(FB_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          first_name: values.full_name || baseData.first_name,
          email: values.email,
          phone: values.phone,
          event_time: Date.now()
        })
      });
      fbResult = await fbResponse.json();
      console.log('Facebook API response received');
    } catch (fbError) {
      console.error('Facebook conversion error (continuing anyway):', fbError);
      fbResult = { error: fbError.message };
    }
    
    // Step 5: Pinterest conversion for new submissions
    try {
      console.log('Calling Pinterest conversion API');
      const pinterestResponse = await fetch(PINTEREST_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          first_name: baseData.first_name,
          last_name: baseData.last_name,
          email: values.email,
          phone: values.phone,
          event_time: Date.now()
        })
      });
      pinterestResult = await pinterestResponse.json();
      console.log('Pinterest API response received');
    } catch (pinterestError) {
      console.error('Pinterest conversion error (continuing anyway):', pinterestError);
      pinterestResult = { error: pinterestError.message };
    }
    
    console.log('===== Form submission completed successfully =====');
    
    // Return success with all data for new submissions
    return {
      success: true,
      alreadySubmitted: false,
      timestamp: new Date().toISOString(),
      formData: values,
      crmResponse: crmResult,
      emailResponse: emailResult,
      fbResponse: fbResult,
      pinterestResponse: pinterestResult
    };
  } catch (error) {
    console.error('===== CRITICAL ERROR in form submission =====', error);
    return {
      success: false,
      error: error.message,
      timestamp: new Date().toISOString()
    };
  }
}; 